import React from "react";
import Tweet from "./Tweet";

interface WallOfLoveProps {
  wallOfLove: any[];
}

export default function WallOfLove(props: WallOfLoveProps) {
  return (
    <div className="pt-8 px-4 lg:columns-3 md:columns-2 sm:columns-1 break-inside-avoid gap-2 space-y-2">
      {props.wallOfLove.map((element) => {
        return (
          <div className="w-full break-inside-avoid">
            <Tweet
              name={element.name}
              username={element.username}
              tweetUrl={element.tweetUrl}
              tweetBody={element.tweetBody}
              userImage={element.userImage}
            />
          </div>
        );
      })}
    </div>
  );
}
