import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Theme } from "@material-ui/core";
import WallOfLove from "../../tailwindComponents/WallOfLove";

interface ImpactPageProps {
  pageContext: {
    totalUsers: number;
    lastUpdated: string;
    wallOfLove: any[];
  };
}

export default function ImpactPage(props: ImpactPageProps) {
  const classes = useStyles();
  const [totalUsers, setTotalUsers] = useState(props.pageContext.totalUsers);

  /* Uncomment if we want a timer */

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const newTotalUsers = totalUsers + 1;
  //     setTotalUsers(newTotalUsers);
  //   }, 5000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [totalUsers]);

  return (
    <>
      <Layout
        maxWidth="lg"
        pageTitle="Impact - JetpackCompose.app"
        pageDescription="How many developers have been helped by JetpackCompose.app?"
        pageSlug="/impact"
        pageImageUrl="/landing.png"
        seoImageUrl="/landing.png"
      >
        <div className={classes.impactPageRoot}>
          <Box width={1}>
            <h1 className={classes.prefix}>
              How many developers have we helped?
            </h1>
          </Box>
          <Box width={1} className={classes.totalUsers}>
            {/* Logic for formatting number from here - https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript */}
            {totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Box>
          <Box width={1} className={classes.suffix}>
            Last update on {props.pageContext.lastUpdated}
          </Box>
        </div>
        <div className={classes.wallOfLoveContainer}>
          <WallOfLove wallOfLove={props.pageContext.wallOfLove} />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  impactPageRoot: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // height: "60vh",
    padding: 32,
  },
  impactPageContent: {},
  prefix: {
    fontWeight: 400,
    fontFamily: "Nunito Sans",
    color: "#222222",
    marginBottom: 16,
    fontSize: 32,
  },
  totalUsers: {
    fontWeight: 700,
    fontFamily: "Nunito Sans",
    color: "#4636f7",
    [theme.breakpoints.up("md")]: {
      fontSize: 140,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 80,
    },
    marginBottom: 16,
  },
  suffix: {
    fontWeight: 400,
    fontFamily: "Nunito Sans",
    color: "#222222",
    marginBottom: 16,
    fontSize: 18,
  },
  wallOfLoveContainer: {
    marginBottom: 64,
  },
}));
